import Head from 'next/head'
import { ErrorPage } from 'components'

const ErrorPageApp = ({ statusCode }: { statusCode: number }) => {
  if (statusCode == 404) {
    return (
      <>
        <Head>
          <title>Página no encontrada | Alvi</title>
          <meta
            content='noindex'
            name='robots'
          />
        </Head>
        <ErrorPage
          body1='Estamos limpiado nuestros pasillos'
          body2='No encontramos lo que estás buscando.'
          errorType='404'
          redirect={true}
          title='Ooops...'
        />
      </>
    )
  }

  return <ErrorPage errorType='500' />
}

ErrorPageApp.getInitialProps = ({ res, err }) => {
  if (typeof window == 'undefined') {
    err = new Error('server', { cause: res.req.headers })
  } else {
    err = new Error('client', { cause: res.req.headers })
  }

  const errCode = err ? err.statusCode : 404
  const statusCode = res ? res.statusCode : errCode
  return { statusCode }
}

export default ErrorPageApp
